<template>
  <div>
    <div class="all-height d-flex justify-center">
      <div class="all-width">
        <v-card :loading="loading" :disabled="loading" loader-height="2">
          <v-card-text class="pb-0">
            <div class="d-flex align-center">
              <v-btn
                v-if="$nova.hasRight(right, 'add')"
                rounded
                color="primary"
                @click="
                  planDilog = true;
                  planObject = {};
                  error = {};
                "
                elevation="0"
                small
                class="mr-2 mt-1"
                ><v-icon class="mr-1">mdi-plus</v-icon>Add</v-btn
              >
              <lb-string
                label="Search"
                outlined
                hidedetails
                v-model="seachList"
              ></lb-string>
              <v-spacer></v-spacer>
              <v-chip label small class="border-left-error" outlined
                >Disabled</v-chip
              >
              <v-btn icon small class="ml-2" @click="refreshData()"
                ><v-icon>mdi-refresh</v-icon></v-btn
              >
            </div>
          </v-card-text>
          <v-card-text>
            <v-data-table
              :headers="PlanHeader"
              :items="planData"
              hide-default-footer
              dense
              :items-per-page="-1"
              :search="seachList"
            >
              <template #[`item.name`]="{ item }">
                <td class="border-left-error" v-if="!item.status">
                  {{ item.name }}
                </td>
                <td class="border-left-transparent" v-else>{{ item.name }}</td>
              </template>

              <template #[`item.product`]="{ item }">
                {{item.product_detail.name}}
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <v-chip x-small label v-if="item.status" color="success"
                  >Active</v-chip
                >
                <v-chip x-small label v-else color="error">InActive</v-chip>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu offset-y transition="slide-y-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="view-on-hover-item-d mx-0 px-0"
                      icon
                      v-bind="attrs"
                      v-on="on"
                      ><v-icon>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list dense class="pa-0 border-left-default">
                    <v-list-item
                      v-if="$nova.hasRight(right, 'edit')"
                      @click="planEdit(item)"
                    >
                      <v-list-item-title
                        ><v-icon left>mdi-pencil</v-icon>Edit</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      v-if="
                        item.status && $nova.hasRight(right, 'enable/disable')
                      "
                      @click="disableEnable(item._id, 'disable')"
                    >
                      <v-list-item-title
                        ><v-icon color="error" left>mdi-cancel</v-icon
                        >Disable</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      v-else-if="$nova.hasRight(right, 'enable/disable')"
                      @click="disableEnable(item._id, 'enable')"
                    >
                      <v-list-item-title
                        ><v-icon left color="success">mdi-check-bold </v-icon
                        >Enable</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
    </div>

    <v-dialog v-model="planDilog" max-width="600" persistent>
      <v-card class="">
        <v-card-text class="primary white--text d-flex align-center py-2 px-4">
          <span class="subtitle-1 white--text" v-if="planObject._id"
            >Update Subscription Plan</span
          >
          <span class="subtitle-1 white--text" v-else
            >Create New Subscription Plan</span
          >
          <v-spacer></v-spacer>
          <v-btn icon @click="planDilog = false" class="ml-2 white--text"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-text>
        <v-card-text class="mt-4 pt-4">
          <v-row>
            <v-col cols="12" class="my-0 py-1">
              <lb-dropdown
                class="flex-grow-1"
                label="Product"
                v-model="planObject.product"
                :items="productList"
                :error="errors['product']"
                itemtext="name"
                itemvalue="_id"
              />
            </v-col>
            <v-col cols="7" class="my-0 py-1">
              <lb-string
                label="Name"
                v-model="planObject.name"
                :error="errors['name']"
              />
            </v-col>
            <v-col cols="5" class="my-0 py-1">
              <lb-string
                label="Code"
                v-model="planObject.code"
                :error="errors['code']"
              />
            </v-col>
            <v-col cols="12" class="my-0 py-1" v-if="planObject.name === 'Free'">
              <lb-number
                min="0"
                label="Period Days"
                v-model="trialPeriod" 
              />
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <lb-string
                label="Description "
                v-model="planObject.description"
                :error="errors['description']"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="d-flex">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            v-if="planObject._id"
            @click="planUpdate()"
            >Update</v-btn
          >
          <v-btn small color="primary" v-else @click="planCreate()"
            >Create</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      right: "subscription_plan",
      loading: false,
      seachList: null,
      errors: {},
      planObject: {},
      planDilog: false,
      PlanHeader: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Product", value: "product", sortable: false },
        { text: "Code", value: "code", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "status", value: "status", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      planData: [],
      planList: [],
      productList: [],
      trialPeriod:0,
    };
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.loading = true;
      this.axios
        .post("/v2/subscription/plan/get")
        .then((dt) => {
          if (dt.data.status === "success") {
            this.planData = dt.data.data;
            this.axios
              .post("/v2/subscription/product/get")
              .then((dt) => {
                if (dt.data.status === "success") {
                  this.productList = dt.data.data;
                } else throw new Error(dt.data.message);
              })
              .catch((err) => {
                this.$store.commit(
                  "sbError",
                  err.message || err || "Unknown error!"
                );
                console.log(err);
              })
              .finally(() => {
                this.loading = false;
              });
          } else throw new Error(dt.data.message);
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    planCreate() {
      console.log(this.planObject, "planObject");
      this.loading = true;
      this.axios
        .post("/v2/subscription/plan/add", {
          data: this.planObject,
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.planDilog = false;
            this.planObject = {};
            this.$store.commit("sbSuccess", "added successfully");
            this.refreshData();
          } else {
            this.errors = {};
            if (dt.data.data.length > 0) {
              if (dt.data.data[0].index0) this.errors = dt.data.data[0].index0;
            }
            throw new Error(dt.data.message || "Error Adding Product");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    planEdit(item) {
      this.planObject = { ...item };
      this.trialPeriod = this.planObject.parameters.trial_period;
      this.planDilog = true;
      this.errors = {};
    },

    planUpdate() {
      this.planObject.parameters["trial_period"] = this.trialPeriod;
      this.loading = true;
      this.axios
        .post("/v2/subscription/plan/edit/" + this.planObject._id, {
          data: this.planObject,
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.planDilog = false;
            this.planObject = {};
            this.$store.commit("sbSuccess", "Update successfully");
            this.refreshData();
          } else {
            this.errors = {};
            if (dt.data.data.length > 0) {
              if (dt.data.data[0].index0) this.errors = dt.data.data[0].index0;
            }
            throw new Error(dt.data.message || "Error Updateing Product");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    disableEnable(id, action) {
      this.loading = true;
      let endpoint;
      if (action === "disable") {
        endpoint = "/v2/subscription/plan/disable/";
      } else if (action === "enable") {
        endpoint = "/v2/subscription/plan/enable/";
      } else {
        throw new Error("Invalid action");
      }
      this.axios
        .post(endpoint + id)
        .then((ele) => {
          if (ele.data.status === "success") {
            this.refreshData();
          } else {
            throw new Error("Error While " + action);
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>